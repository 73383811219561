.login{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.login-card {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 0 auto;
  }
  
  .login-header {
    color: #1877F2;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .login-subheader {
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #DDDFE2;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .login-button {
    background-color: #1877F2;
    color: white;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
.app-body {
  text-align: center;
  height: 100vh;
}

.black-bar {
  color: #FFFF;
  background-color: black;
  text-align: center;
}

.blur-glass {
  backdrop-filter: blur(4px) saturate(200%);
  -webkit-backdrop-filter: blur(4px) saturate(200%);
  background-color: rgba(255, 255, 255, 0);
}

.light-glass {
  backdrop-filter: blur(2px) saturate(180%);
  -webkit-backdrop-filter: blur(2px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.header {
  color: #FFFF;
  text-shadow: 0 0 0.7em rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  max-width: 100%;
}

@media (max-width: 760px) {
  .header {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.header-menu {
  transition: background-color 2s, color 3s;
}

.header-menu:hover {
  background-image: linear-gradient(to right, rgba(9, 255, 0, 0.39), rgba(0, 255, 255, 0.418));
  border-radius: 15px;
}

.titlebox {
  display: flex;
  margin-top: 40px;
  height: 340px;
  /* background-color: aqua; */
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  /* background-color: rgb(255, 0, 0); */
}

.title-text {
  font-size: 108px;
  /* text-shadow: 0 0 0.7em rgba(84, 240, 23, 0.589); */
  background: radial-gradient(circle, rgba(233, 144, 28, 0.836), rgba(35, 243, 191, 0.959));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.title-intro-text {
  padding: 10px;
  color: #FFFF;
  /* text-shadow: 0 0 0.7em rgb(255, 255, 255); */
  font-size: 20px;
}

.title-icon {
  width: 40%;
  /* background-color: rgb(43, 255, 0); */
}

.title-3d-viewer{
  /* background-color: rgb(43, 255, 0); */
  height: 560px;;
}

.imagination-box {
  margin-top: 40px;
  color: #FFFF;
  /* text-shadow: 0 0 0.7em rgb(255, 255, 255); */
  font-size: 20px;
  width: auto;
  padding: 15px;
}

.imagination-text {
  font-weight:bolder;
  font-size: 65px;
  text-shadow: 0 0 0.7em rgba(84, 240, 23, 0.589);
  background: radial-gradient(circle, rgba(189, 233, 28, 0.836), rgba(35, 243, 191, 0.959));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.title-screen-cards{
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 20px;
  height: 180px;
  width: 20%;
}

.footer{
  display: flex;
  /* background-color: #e70b0b; */
  color: white;
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  bottom: 0;
  width: 100%;
  height: 'auto';
}

.footer-card-info{
  margin: 20px;
  padding: 20px;
height: 240px;
width: 70%;
}

.footer-card-social{
  margin: 20px;
  padding: 20px;
  height: 240px;
  width: 30%;
}

.assets{
  color: #FFFF;
  text-shadow: 0 0 0.7em rgb(255, 255, 255);
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
}

.collection-list{
  /* background-color: #14dd2197; */
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1em;
  padding: 1em;
  min-height: 60%;
  width: 100%;
}

.asset-card {
  color: #FFFF;
  text-shadow: 0 0 0.7em rgb(255, 255, 255);
  margin: 10px;
  /* background-color: #2114dd97; */
  height: 180px;
  width: 140px;
}